import React from 'react';
import { Link } from "gatsby"
import Layout from '../components/layout'
import Seo from '../components/seo'

const TermsPrivacyPolicy = () => {
    return (
    <Layout isHomePage={false}>
        <div className="px-5 mb-20">

            <div className="px-5 my-10 text-center rounded-xl">
                <h1 className="text-3xl font-bold mb-2 text-gameOnDarkGreen">Terms & Privacy Policy</h1>
                <p className="text-lg font-semibold mb-2 text-gameOnGreen my-5">We take your privacy seriously and will only use your personal information to provide the services you have requested from us.</p>
            </div>

            <div className="container mx-auto">
                <div className="bg-gameOnLightGreen p-10 rounded-3xl termsText">
                <p>Last Modified: January 23, 2023</p>
                <p> OUR PROVIDERS DO NOT ADDRESS MEDICAL EMERGENCIES. IF YOU BELIEVE YOU ARE EXPERIENCING A MEDICAL EMERGENCY, YOU SHOULD DIAL 9-1-1 AND/OR GO TO THE NEAREST EMERGENCY ROOM AND SHOULD NOT PROCEED WITH CLINICAL SERVICES USING GAME ON PLATFORM.</p>
                <p> IF YOU ARE CONTEMPLATING SUICIDE, CONTACT 911 OR THE NATIONAL SUICIDE PREVENTION LINE AT 1-800-273-TALK (8255).</p>
                <p>Introduction</p>
                <p> These terms of use (the &ldquo;Terms&rdquo;) describe your rights and responsibilities with regard to the Game On website at https://thegameonapp.com/ and mobile application (the &ldquo;Platform&rdquo;) owned and operated by Game On App LLC. In these Terms, &ldquo;we&rdquo;, &ldquo;our&rdquo;, &ldquo;us&rdquo;, and &ldquo;Game On&rdquo; collectively refer to Game On App LLC. The terms &ldquo;you&rdquo; and &ldquo;yours&rdquo; refer to the person using the Platform. Use of the Platform is governed by these Terms and our Privacy Policy. By accessing or using the Platform, you acknowledge that you have read, understood and agreed to be legally bound by and comply with these Terms and our Privacy Policy.</p>
                <p> Even though you may have arrived to the Platform through a website or mobile application operated or controlled by a third party, including by an affiliate of Game On, you understand and agree that these Terms are entered into between you and Game On.</p>
                <p> You agree that when you use or enter the Platform, you affirmatively consent to conduct business electronically with Game On and engage in health-oriented activities with health professionals and professional entities affiliated with Game On, and such processes have the same force and effect as your written signature. You agree and consent to Game On, Game On affiliates or certain affiliated professional entities sending you disclosures, messages, notices, and other communications to your designated mobile phone and email account. If you do not agree with any of these Terms or our Privacy Policy, you may not use the Platform.</p>
                <p></p>
                <p>II. Modification of the Terms</p>
                <p>We reserve the right, in our sole discretion, to amend these Terms, in whole or in part, at any time and for any reason, without penalty or liability to you or any third party. You should check the Terms from time to time when you use the Platform to determine if any changes have been made. You can determine when the Terms were last revised by referring to the &ldquo;Last Modified&rdquo; notation above. If you use the Platform after the amended Terms have been posted, you will be deemed to have agreed to the amended Terms. If any of the provisions of these Terms are not acceptable to you, your sole and exclusive remedy is to discontinue your use of the Platform.</p>
                <p></p>
                <p>IV. Eligibility</p>
                <p>In order to use the Platform, the following must be true:</p>
                <p> You are at least 18 years of age or older.</p>
                <p>You live in the United States.</p>
                <p>You agree to be legally bound by and comply with these Terms of Use.</p>
                <p></p>
                <p>You must have compatible computing and/or mobile devices, access to the Internet, and certain necessary software in order to use the Platform. Fees and charges may apply to your use of the mobile services and to the Internet.</p>
                <p> You understand and agree that satisfying the above requirements does not guarantee that you will receive the services through the Platform. In addition to the above requirements, Game On and its affiliated professional entities reserve the right to change or include new requirements as deemed appropriate in their sole discretion without providing prior notice to you.</p>
                <p></p>
                <p>VI. Registration, User Accounts, and User Data</p>
                <p></p>
                <p>To access the Platform, you must first enroll to establish an individual user account (&ldquo;Account&rdquo;), by providing certain information as set out in the Privacy Policy. You agree that you will not create more than one Account or create an Account for anyone other than yourself without first receiving permission from the other person. In exchange for your use of the Platform and, if applicable, in order for Game On to send messages to you, you agree to:</p>
                <p></p>
                <p>provide true, accurate, current, and complete information about yourself as prompted by our Account enrollment form (understanding that during therapy sessions you may use a nickname); and to maintain, and promptly update such Account information, each time you log on, as far as possible to keep it true, accurate, current, and complete. If you provide any information that is untrue, inaccurate, not current, or incomplete, or Game On has reasonable grounds to suspect that such Account information is untrue, inaccurate, not current, or incomplete, Game On reserves the right to transition your treatment to another Provider outside of Game On or take other steps as appropriate. remain responsible for maintaining the confidentiality of your Account password and username (if any), and any other security information related to your Account at all times. Game On will not be liable for any loss that you incur as a result of someone else accessing and using your Account, either with or without your knowledge.</p>
                <p> The Platform may not be accessible at any time, for any period, or for any reason, and Game On will not be liable if for any reason all or any part of the Platform is unavailable at any time or for any period.</p>
                <p> Upon registration of an account, the Platform may contain forms or fields that allow you to enter, submit or transmit to Game On user information or data (&ldquo;User Data&rdquo;) on or through the Platform. You understand and agree that any User Data provided by you on or through the Platform may be used, copied or displayed by Game On, Game On may create derivative works of any such data, and Game On may provide such data to our service providers, our successors and assigns, and medical providers and their affiliated professional entities, in performance of its services.</p>
                <p>You grant Game On, our service providers, our successors and assigns, and medical providers and their affiliated professional entities, the fully transferable and sublicenseable right and license to use, reproduce, modify, analyze, perform, display, distribute, and otherwise disclose to third parties any User Data you submit on or through the Platform for the purposes of providing services to you; conducting research or analyses of such data; and designing, developing, implementing, modifying and/or improving new, current or future features, products and services of Game On using such data.</p>
                <p></p>
                <p>VII. Your Responsibilities and Acknowledgement</p>
                <p>As a condition of your use of the services provided through the Platform, you agree to the following:</p>
                <p>All Your Information provided through the Platform is accurate, complete and correct, and you will accurately maintain and update any of Your Information that you have provided to Game On.</p>
                <p>Your permission to use the Platform is personal (the Platform will be used only by you), and your identification information is accurate and truthful. You agree to keep confidential your username and password and that you will exit from your account at the end of each session. You are responsible for all activities that occur under your account and for maintaining the confidentiality of your password. You are responsible for changing your password promptly if you think it has been compromised. You may not transfer or share your password with anyone, or create more than one account. You may not use anyone else&rsquo;s account at any time.</p>
                <p> You agree to immediately notify Game On of any unauthorized use of your username, password or any other breach of security that you become aware of involving or relating to the services by emailing Game On at info@thegameonapp.com.</p>
                <p> You may be asked to provide additional information to Game On, its affiliated professional entities, or applicable medical provider(s). You may elect to withhold requested information; however, if you do so, you may not use the Platform or any other related services.</p>
                <p>You understand and agree that provision of services through the Platform depends on the completeness and accuracy of Your Information. Game On is unable to verify all of Your Information. Therefore, Game On is not responsible for any consequences if Your Information is inaccurate or incomplete. If Your Information is inaccurate, incomplete, or not maintained, or Game On has reasonable grounds to suspect as much, Game On has the right to suspend or terminate your account and your use of the Platform. In addition, Game On may take any and all actions it deems necessary or reasonable to maintain the security of the Platform, services and your Secure User account.</p>
                <p></p>
                <p>IX. Restrictions on Use</p>
                <p>You will not use, or encourage or permit others to use, our Platform except as expressly permitted in these Terms. You will not:</p>
                <p> Use or attempt to use the Platform or the services for any other person than yourself;</p>
                <p> Access or use the Platform in any manner or for any purpose that infringes, misappropriates, or otherwise violates any intellectual property right or other right of any third party, or that violates any applicable local, state or federal law or regulation, or is prohibited by these Terms;</p>
                <p> &ldquo;Jailbreak&rdquo; your mobile operating system. The Platform is intended for use only on a mobile phone that runs an unmodified manufacturer-approved operating system. Using the Platform on a mobile phone with a modified operating system may undermine security features that are intended to protect your protected health information from unauthorized or unintended disclosure. You may compromise your protected health information if you use the Platform on a mobile phone that has been modified. Use of the Platform on a mobile phone with a modified operating system is a material breach of these Terms;</p>
                <p> License, sublicense, sell, resell, transfer, assign, distribute or otherwise commercially exploit or make available to any third party the Platform or related materials in any way;</p>
                <p> Use or access the Platform to create or develop competing products or services or for any other purpose that is to Game On&rsquo;s detriment or commercial disadvantage;</p>
                <p> Take any action or use the Platform in any manner which could damage, destroy, disrupt, disable, impair, overburden, interfere with, or otherwise impede or harm in any manner our Platform or any content, in whole or in part;</p>
                <p> Disrupt, interfere with, violate the security of, or attempt to gain unauthorized access to our Platform or any computer network;</p>
                <p> Bypass, breach, avoid, remove, deactivate, impair, descramble, or otherwise circumvent any security device, protection, or technological measure implemented by Game On or any of our service providers to protect our Platform;</p>
                <p> Input, upload, transmit, distribute, or otherwise run or propagate any virus, application, Trojan horse, or any other harmful computer code that could damage or alter a computer, portable device, computer network, communication network, data, or our Platform, or any other system, device, or property;</p>
                <p>Remove, delete, alter, or obscure any trademarks, specifications, warranties, or disclaimers, or any copyright, trademark, patent, or other intellectual property or proprietary rights notices from our Platform or any content made available to you on or through our Platform;</p>
                <p> Use any manual process or automated device to monitor or copy any content made available on or through our Platform for any unauthorized purpose except as permitted in Section &#8206;&#8206;XIV;</p>
                <p> Copy, duplicate, download, store in a retrieval system, publish, transmit or otherwise reproduce, transfer, distribute, store, disseminate, aggregate, use as a component of or as the basis for a database or otherwise use in any form or by any means any data, text, reports, or other materials related to Game On or third-party content from the Platform; or</p>
                <p> Encourage or enable any other individual to do any of the foregoing.</p>
                <p>X. License and Use</p>
                <p></p>
                <p>Subject to your compliance with these Terms, Game On grants you a personal, limited, revocable, nonexclusive, and nontransferable license to view, download, access, and use the Platform and its content, solely for your personal and non-commercial use. No other right, title, or interest in or to the Platform is transferred to you, and all rights not expressly granted are reserved by Game On or its licensors. You are not permitted to reproduce, publish, transmit, distribute, display, modify, create derivative works from, sell or participate in any sale of, or exploit in any way, in whole or in part, any such content for commercial use.</p>
                <p></p>
                <p>XI. Disclaimer; Limited Health Care Services</p>
                <p></p>
                <p>The Platform is structured for use specific to certain health care services and is not, and should not, be considered, or used as comprehensive medical advice, care, diagnosis or treatment. You are always advised to exercise a high level of care and caution when making any mental health or medical decision. You should never disregard, avoid, or delay obtaining medical advice from your primary doctor or other qualified healthcare provider, or using or combining Game On with traditional face-to-face appointments, solely because of information you saw on the Platform or advice you received through the Provider. Game On supports a holistic approach to mental health and the choices you have, to create the best therapeutic journey that matches your individual needs. If you feel that the Provider does not meet your expectations, you may change to a different Provider at any time.</p>
                <p> Other than the guidance and advice you receive directly to you from your Provider, the graphics, educational and research sources and other incidental information on the Platform should not be considered medical advice. Always seek the advice of your physician or other qualified healthcare provider with any questions you may have regarding general personal health, medical conditions, or drugs or medications, and before commencing or discontinuing any course of treatment, drug or medication.</p>
                <p></p>
                <p>XII. Telehealth Consent</p>
                <p>Telehealth involves the delivery of health and wellness services using electronic communications, information technology, or other means between a licensed, certified, or registered healthcare professional at one location and a patient in another location about a clinical matter. Please review the full &ldquo;Telehealth Informed Consent,&rdquo; which informs you about the treatment methods, risks, and limitations of utilizing telehealth to meet your health and wellness needs. In order to receive Health Care services, you will be required to agree to the Telehealth Informed Consent regarding the use of telehealth.</p>
                <p></p>
                <p>XIII. Payment</p>
                <p></p>
                <p>Game On is not a healthcare provider and only contracts with mental health professionals to see its clients. While Game On provides the platform necessary to facilitate the telehealth visit, it does not employ or control the activities of the mental health professional. You are responsible for payment to the Provider based on the set fee schedule provided to you. As part of its services, Game On will collect the fee from you and transfer these funds to the Provider.</p>
                <p></p>
                <p>When you submit Your Information for Provider services, you agree to pay all fees due. You will see a prompt for your payment details, such as your credit card information and any promotional codes you may have. By entering your payment information and submitting your request, you authorize us, our affiliates, or our third-party payment processors to charge the amount due. Fees are not subject to or eligible for a refund.</p>
                <p>  You understand that Game On&rsquo;s affiliated medical professional entities are not contracted health care providers with any health insurance plans (commercial, government, or otherwise) (i.e., Game On is an &ldquo;out-of-network&rdquo; provider), and therefore, you understand and agree that you are exclusively and solely responsible for paying all fees due to receive the services provided to you, including any fees charged by the Game On affiliated medical professional entities. A super bill will be provided to you upon request, if you wish to submit a claim for the services provided to you to your health insurance plan to request out-of-network reimbursement. You are exclusively and solely responsible for filing any reimbursement claims with your health insurance plan. Amounts collected by Game On will include fees charged by medical providers for services. In the event that your credit card expires or Game On, our affiliates, or our third-party payment processors are unable to process your payment, you may receive notice for you to provide an alternative payment method. Game On and/or the medical provider(s) have no obligation to provide any services unless and until full payment has been received and/or verified.</p>
                <p>You also understand and agree that, because Game On&rsquo;s Providers are not contracted with any health insurance plan to provide the services, including federal or state government health care programs, like Medicaid and Medicare.</p>
                <p> Game On itself does not accept government insurance, including Medicaid or Medicare, for its services. Again, if you would like to use our services as an out-of-network provider, you will need to pay cash for those services. We can provide you a receipt, so you can seek out-of-network reimbursement.</p>
                <p></p>
                <p>XIV. Privacy</p>
                <p></p>
                <p>Game On understands the importance of confidentiality and privacy regarding Your Information. Please see our Privacy Policy for a description of how we may collect, use and disclose Your Information in connection with the Platform.</p>
                <p></p>
                <p>XV. Intellectual Property</p>
                <p></p>
                <p>As between Game On and you, Game On is the sole and exclusive owner of all right, title and interest in and to the Platform and its content, features and functionality (including, without limitation, all information, software, text, displays, images, video, audio, selection, arrangement and look and feel), and all intellectual property rights therein, and any suggestions, ideas or other feedback provided by you. Any copy, modification, revision, enhancement, adaptation, translation, or derivative work of the Platform shall be owned solely and exclusively by Game On or its licensors, including all intellectual property rights therein. You have permission to use the Platform solely for your personal and non-commercial use on the condition that you comply with these Terms. No other right, title or interest in or to the Platform is transferred to you, and all rights not expressly granted are reserved by us or our affiliates.</p>
                <p> Certain names, logos, and other materials displayed in and through the Platform may constitute trademarks, trade names, services marks or logos (&ldquo;Trademarks&rdquo;) of Game On or its affiliates or Providers. You are not authorized to use any such Trademarks without the express written permission of Game On or its affiliates or Providers. Ownership of all such Trademarks and the goodwill associated therewith remains with us or our affiliates or Providers.</p>
                <p></p>
                <p>XVI. Links to Third-Party Hyperlinks and Websites</p>
                <p></p>
                <p>The Platform may contain hyperlinks or references to other websites (&ldquo;Linked Sites&rdquo;) operated by third parties. The Linked Sites may not be under our control; therefore, we are not responsible for the information, products or services described thereon, or for the content of any Linked Site, including, without limitation, any link contained in a Linked Site, or any changes or updates to a Linked Site. We are providing these Linked Sites to you only as a convenience, and the inclusion of any link does not necessarily imply endorsement of the Linked Site or any association with its operators. Your use of these Linked Sites is at your own risk, and we are not liable to you in any way, either directly or indirectly, for any content, errors, damage or loss caused by or in connection with use of or reliance on information contained in or provided to Linked Sites.</p>
                <p> You may have arrived to the Platform through a Linked Site, including a Linked Site controlled by a parent, subsidiary or affiliate of Game On. You understand and agree that we are not responsible for the information, products or services described on those Linked Sites and only these Terms will apply to your use of or access to the Platform.</p>
                <p></p>
                <p>XVII. Disclaimer of Warranties</p>
                <p></p>
                <p>YOU ACKNOWLEDGE AND AGREE THAT THE PLATFORM AND THE SERVICES ARE PROVIDED THROUGH THE PLATFORM ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS. YOUR USE OF THE PLATFORM IS AT YOUR SOLE RISK.</p>
                <p></p>
                <p>GAME ON AND ITS AFFILIATES AND CONTRACTORS AND THEIR RESPECTIVE OFFICERS, DIRECTORS, MANAGERS, PARTNERS, MEMBERS, EMPLOYEES, AND AGENTS (COLLECTIVELY &ldquo;RELATED PERSONS&rdquo;) MAKE NO REPRESENTATIONS OR WARRANTIES AND SPECIFICALLY DISCLAIM ANY AND ALL WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, WITH RESPECT TO THE PLATFORM AND THE SERVICES, INCLUDING ANY REPRESENTATIONS OR WARRANTIES WITH RESPECT TO MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, AVAILABILITY, SECURITY, ACCURACY, FREEDOM FROM VIRUSES OR MALWARE, COMPLETENESS, TIMELINESS, FUNCTIONALITY, RELIABILITY, SEQUENCING OR SPEED OF DELIVERY. WE MAKE NO WARRANTIES OR REPRESENTATIONS THAT YOUR USE OF THE PLATFORM OR THE SERVICES WILL NOT INFRINGE THE RIGHTS OF THIRD PARTIES.</p>
                <p> TO THE FULLEST EXTENT OF APPLICABLE LAW, NEITHER GAME ON NOR ITS RELATED PERSONS WILL BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY YOUR RELIANCE ON INFORMATION OBTAINED THROUGH THE PLATFORM. IT IS YOUR RESPONSIBILITY TO EVALUATE THE ACCURACY, COMPLETENESS, TIMELINESS, RELIABILITY OR USEFULNESS OF THE PLATFORM. FURTHERMORE, GAME ON DOES NOT GUARANTEE THAT THE PLATFORM WILL BE UNINTERRUPTED, OR FREE FROM ERROR, DEFECT, LOSS, DELAY IN OPERATION, CORRUPTION, CYBER ATTACK, VIRUSES, INTERFERENCE, HACKING, MALWARE, OR OTHER SECURITY INTRUSION, AND GAME ON DISCLAIMS ANY LIABILITY RELATING THERETO.</p>
                <p> YOU UNDERSTAND AND AGREE THAT ANY CONTENT, MATERIAL AND/OR INFORMATION OBTAINED THROUGH THE USE OF THE PLATFORM ARE USED AT YOUR SOLE RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER OR MOBILE PHONE OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF SUCH CONTENT, MATERIAL AND/OR INFORMATION.</p>
                <p></p>
                <p>XVIII. Limitation of Liability</p>
                <p></p>
                <p>TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW AND EXCEPT AS SET FORTH IN THIS SECTION, NEITHER GAME ON NOR ITS RELATED PERSONS OR LICENSORS WILL BE LIABLE TO YOU OR TO ANY PARTY FOR ANY CLAIMS, LIABILITIES, LOSSES, COSTS OR DAMAGES UNDER ANY LEGAL OR EQUITABLE THEORY, WHETHER IN TORT (INCLUDING NEGLIGENCE), CONTRACT, STRICT LIABILITY OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO, ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOSS OF DATA OR LOSS OF GOODWILL, SERVICE INTERRUPTION, COMPUTER OR MOBILE PHONE DAMAGE, OR SYSTEM FAILURE, OR THE COST OF SUBSTITUTE PRODUCTS OR SERVICES, OR FOR ANY DAMAGES FOR PERSONAL OR BODILY INJURY OR EMOTIONAL DISTRESS, INCLUDING DEATH, ARISING OUT OF OR IN CONNECTION WITH ANY ACCESS, USE OF (OR INABILITY TO USE) THE PLATFORM OR ANY SERVICES PROVIDED THROUGH THE PLATFORM. THIS IS TRUE EVEN IF GAME ON OR RELATED PERSONS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES.</p>
                <p> TO THE EXTENT PERMITTED BY LAW AND SUBJECT TO THIS SECTION, THE TOTAL LIABILITY OF GAME ON AND ITS RELATED PERSONS FOR ANY CLAIMS UNDER THESE TERMS SHALL BE LIMITED TO THE AMOUNT OF PAYMENT MADE BY YOU TO GAME ON IN THE PAST 12 MONTHS, UNLESS OTHERWISE REQUIRED BY APPLICABLE LAW.</p>
                <p></p>
                <p>California Residents</p>
                <p>The Board of Behavioral Sciences receives and responds to complaints regarding services provided by therapists, clinical social workers, or professional clinical counselors. You may contact the board online at www.bbs.ca.gov, or by calling (916) 574-7830.</p>
                <p></p>
                <p>XIX. Indemnification</p>
                <p>You agree to indemnify, defend, and hold Game On and any of its Related Persons, licensors, and suppliers harmless from and against any and all third-party claims, demands, liabilities, costs or expenses, including attorneys&rsquo; fees and costs, arising from or related to: (i) any breach by you of these Terms, (ii) your use of material or features available on the Platform in an unauthorized manner, and/or (iii) a violation by you of any and all applicable laws, rules, or regulations.</p>
                <p></p>
                <p>XX. Modifications to the Platform</p>
                <p></p>
                <p>Game On reserves the right at any time and for any reason to modify, or temporarily or permanently discontinue, the Platform, or any portion thereof, with or without notice. You agree that Game On shall not be liable to you and to any third party for any modification, suspension, or discontinuance of the Platform.</p>
                <p></p>
                <p>XXI. Suspension and Termination Rights</p>
                <p></p>
                <p>The Terms will remain in full force and effect as long as you continue to access or use the Platform. You may terminate the Terms at any time by discontinuing use of the Platform. Your permission to use the Platform automatically terminates if you violate these Terms.</p>
                <p> We may terminate or suspend any of the rights granted by these Terms and your access to our Platform with or without prior notice, at any time, and for any reason. The following provisions survive the expiration or termination of these Terms for any reason whatsoever: Disclaimer of Warranties; Limitation of Liability; Indemnification; Governing Law, Dispute Resolution, Arbitration, Class Action Waiver; and Miscellaneous.</p>
                <p> Subject to applicable law, Game On reserves the right to maintain, delete or destroy all communications and materials posted or uploaded to the Platform pursuant to its internal record retention and/or content destruction policies. After any termination, Game On will have no further obligation to provide the services, except to the extent we are obligated to provide you access to your health records or required to provide you with continuing care under our applicable legal, ethical and professional obligations to you.</p>
                <p></p>
                <p>XXII. Governing Law; Dispute Resolution; Arbitration; Class Action Waiver</p>
                <p></p>
                <p>PLEASE READ THIS SECTION CAREFULLY BECAUSE IT REQUIRES YOU AND GAME ON TO RESOLVE ALL DISPUTES BETWEEN US THROUGH BINDING INDIVIDUAL ARBITRATION AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM GAME ON.</p>
                <p> Governing Law. The Platform is controlled and operated by us from the United States, and is not intended to subject us to the laws or jurisdiction of any state, country or territory other than that of the United States. These Terms will be governed by the laws of the State of California without regard to conflicts of law principles.</p>
                <p> Arbitration Agreement. You and Game On agree that all claims and disputes relating in any way to your use of our Platform, or arising out of or in connection with these Terms, shall be resolved by binding arbitration, to the fullest extent permitted by applicable law, on an individual basis, except for disputes which can be resolved in small claims court, any dispute in which either party seeks equitable relief for the alleged unlawful use of copyrights, trademarks, trade names, logos, trade secrets, or patents, or any dispute already pending at the time you first agree to these Terms. You also agree that any arbitration will take place in Los Angeles, California.</p>
                <p> Waiver of Jury Trial. IN THE EVENT ARBITRATION IS CONTRARY TO APPLICABLE LAW, YOU AND GAME ON WAIVE ANY CONSTITUTIONAL OR STATUTORY RIGHT TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY. You and Game On are instead electing to have claims and disputes resolved by arbitration. Arbitration is the referral of a claim or dispute to one or more persons charged with reviewing the claim or dispute and making a final binding determination to resolve it instead of having it decided by a judge or jury in court. Arbitration procedures are typically more limited, more efficient, and less costly than rules applicable in court and are subject to very limited review by a court. The arbitrator&rsquo;s award shall be binding and may be entered as a judgment in any court of competent jurisdiction.</p>
                <p>No Class Arbitrations, Class Actions, or Representative Actions. YOU AND GAME ON AGREE THAT ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. CLAIMS AND DISPUTES OF MORE THAN ONE CUSTOMER OR USER CANNOT BE BROUGHT AS A CLASS OR OTHER TYPE OF REPRESENTATIVE ACTION, WHETHER WITHIN OR OUTSIDE OF ARBITRATION, OR ON BEHALF OF ANY INDIVIDUAL OR OTHER GROUP. UNLESS BOTH YOU AND GAME ON AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN MORE THAN ONE PERSON&rsquo;S OR PARTY&rsquo;S CLAIMS AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED, REPRESENTATIVE, OR CLASS PROCEEDING. ALSO, THE ARBITRATOR MAY AWARD RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT PARTY&rsquo;S INDIVIDUAL CLAIM(S) OR DISPUTE. ANY RELIEF AWARDED CANNOT AFFECT OTHER GAME ON USERS.</p>
                <p> Arbitration Rules. The Federal Arbitration Act governs the interpretation and enforcement of this dispute resolution provision. Any arbitration between you and Game On will be initiated through the American Arbitration Association (&ldquo;AAA&rdquo;) and will be governed by the AAA Consumer Arbitration Rules. The AAA Rules and filing forms are available at www.adr.org.</p>
                <p></p>
                <p>XXIII. Miscellaneous</p>
                <p></p>
                <p>The Terms set forth the entire understanding and agreement between you and us with respect to the subject matter hereof. If any provision of the Terms is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties&rsquo; intentions as reflected in the provision, and the other provisions of the Terms shall remain in full force and effect. Headings are for reference only and in no way define, limit, construe, or describe the scope or extent of such section. Our failure to act with respect to any failure by you or others to comply with these Terms does not waive our right to act with respect to subsequent or similar failures. You may not assign or transfer your rights or obligations under these Terms without our prior written consent, and any assignment or transfer in violation of this provision shall be null and void.</p>
                <p></p>
                <p>XXIV. Copyright Infringement Claims</p>
                <p></p>
                <p>Game On reserves the right to remove any content or any other material or information available on or through our Platform, at any time, for any reason. Game On otherwise complies with the provisions of the Digital Millennium Copyright Act (&ldquo;DMCA&rdquo;) applicable to Internet service providers (17 U.S.C. &sect; 512, as amended), and responds to clear notices of alleged copyright infringement. This Section &#8206;XXIV describes the procedure that should be followed to file a notification of alleged copyright infringement with Game On.</p>
                <p> Notification of Claimed Copyright Infringement. If you have objections to copyrighted content or material made available on or through our Platform, you may submit a notification to our Designated Agent at the following address: [email].</p>
                <p>Any notification to Game On under 17 U.S.C. &sect; 512(c) alleging copyright infringement must include the following information:</p>
                <p> An electronic or physical signature of the person authorized to act on behalf of the owner of the exclusive right being infringed;</p>
                <p> An identification of the copyrighted work or other intellectual property that you claim has been infringed or, if multiple copyrighted works are covered by a single notification, a representative list of such works;</p>
                <p> An identification of the content or material that you claim is infringing and where it is located on our Platform;</p>
                <p> Information sufficient for Game On to contact you, such as your address, telephone number, and/or email address;</p>
                <p> A statement by you that you have a good-faith belief that the use of the content or material of which you are complaining is not authorized by the copyright owner, its agent, or the law; and</p>
                <p>A signed statement by you that the above information in your notice is accurate and that, under penalty of perjury, you are the copyright owner or authorized to act on the copyright owner&rsquo;s behalf.</p>
                <p></p>
                <p>XXV. Contact Information</p>
                <p>If you have any questions or concerns, please contact info@thegameonapp.com.</p>
                </div>
            </div>
        </div>
    </Layout>
    )
};
export const Head = () => <Seo title="Terms & Privacy Policy | Game On!" />

export default TermsPrivacyPolicy;